<script>
export default {
  props: ["model"],
  data: function () {
    return {
      selected: "",
    };
  },
  computed: {
    workShiftskeys: function () {
      return Object.keys(this.$global.dictionary.workShifts);
    },
    workShifts: function () {
      return this.$global.dictionary.workShifts;
    },
  },
  methods: {
    getSelected(workShift) {
      return workShift === this.model?.workShift?.name;
    },
  },
  beforeMount() {
    this.selected = this.model?.workShift?.name || "";
  },
};
</script>

<template>
  <div class="modelsWorkshiftSelect">
    <select
      name="workShift"
      :class="['modelsWorkshiftSelect__form', model.idUserCollection && 'modelsWorkshiftSelect__form--disabled']"
      v-model="selected"
      @change="$emit('selected', selected)"
    >
      <option value="">Seleccionar turno</option>
      <option v-for="(workShift, index) in workShiftskeys" :key="index + workShift" :value="workShift">
        {{ workShifts[workShift].name }}
      </option>
    </select>
  </div>
</template>

<style lang="scss">
.modelsWorkshiftSelect {
  &__form {
    &--disabled {
      pointer-events: none;
      opacity: 0.8;
      color: gray;
    }
  }
}
</style>
